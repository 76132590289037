export type SmallCompanySalaryRaisePeriodPreference = 1 | 2 | 3 | null

export interface EgaproIndex {
  id: string
  year: number
  startDate: Date
  endDate: Date
  referencePeriodLastUpdatedAt: Date | null
  smallCompanySalaryRaisePeriodPreference: SmallCompanySalaryRaisePeriodPreference
  smallCompanySalaryRaisePeriodPreferenceLastUpdatedAt: Date | null
  hasCustomizedCategorization: boolean
  isComputed: boolean
  subjectWorkforce: number | null
  workforceInCalculation: number | null
  totalScore: number | null
  genderPayGapScore: number | null
  largeCompanyGenderPayRaiseGapScore: number | null
  smallCompanyGenderPayRaiseGapScore: number | null
  largeCompanyGenderPromotionGapScore: number | null
  payRaiseAfterMaternityLeaveScore: number | null
  topHighestSalariesScore: number | null
}

export type EgaproIndexWithPreviousYearScore = EgaproIndex & {
  previousYearTotalScore: number | null
}

export enum EgaproDimensionCategory {
  EGAPRO_EMPLOYMENT_TYPE = 'EGAPRO_EMPLOYMENT_TYPE',
  EGAPRO_FTE_FINANCE = 'EGAPRO_FTE_FINANCE',
  EGAPRO_GENDER = 'EGAPRO_GENDER',
  EGAPRO_CUSTOM_CATEGORY = 'EGAPRO_CUSTOM_CATEGORY',
  EGAPRO_PROFESSIONAL_CATEGORY_TYPE = 'EGAPRO_PROFESSIONAL_CATEGORY_TYPE',
  EGAPRO_AGE_RANGE = 'EGAPRO_AGE_RANGE',
  EGAPRO_REMUNERATION = 'EGAPRO_REMUNERATION',
  EGAPRO_SALARY_RAISE = 'EGAPRO_SALARY_RAISE',
  EGAPRO_PROMOTION = 'EGAPRO_PROMOTION',
  EGAPRO_MATERNITY_LEAVE = 'EGAPRO_MATERNITY_LEAVE',
  EGAPRO_REFERENCE_PERIOD = 'EGAPRO_REFERENCE_PERIOD'
}

export interface EgaproMeasureQuery {
  titleTranslationKey: string
  dimensionCategory: EgaproDimensionCategory
  measure: string
  associatedDimensions?: string[]
  orderDimension?: string
}

export enum SetupTableColumn {
  CONFIGURED = 'CONFIGURED',
  VERIFIED = 'VERIFIED',
  NORMALIZED = 'NORMALIZED',
  SETUP = 'SETUP'
}

export enum ErrorType {
  WAITING = 'WAITING',
  MISSING = 'MISSING'
}

export type EgaproSetupTableErrors = {
  [key in EgaproDimensionCategory]?: { [key in SetupTableColumn]?: ErrorType[] }
}

export type ErrorDetail = {
  dimension: EgaproDimensionCategory
  column: SetupTableColumn
  errorType: ErrorType
}

export type SalaryRaisePeriodPreferenceOption = {
  label: string
  value: number
}
