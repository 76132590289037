import {
  type EgaproMeasureQuery,
  EgaproDimensionCategory
} from '~/types/egaproIndex'
import { DIMENSION_TITLES, MEASURE_TITLES } from '~/constants/analytics'
import { SourceValueDimension } from '~/types/sourceValue'

export const SMALL_COMPANY_LIMIT = 250

export const SALARY_RAISE_PERIOD_OPTIONS = [
  {
    label:
      'legalReports.egaproIndex.setupTable.configurationModal.salaryRaisePeriodOptions.oneYear',
    value: 1
  },
  {
    label:
      'legalReports.egaproIndex.setupTable.configurationModal.salaryRaisePeriodOptions.twoYears',
    value: 2
  },
  {
    label:
      'legalReports.egaproIndex.setupTable.configurationModal.salaryRaisePeriodOptions.threeYears',
    value: 3
  }
]

export const EGAPRO_INDEX_SETUP_MEASURE_QUERIES: EgaproMeasureQuery[] = [
  {
    titleTranslationKey:
      'legalReports.egaproIndex.setupTable.categories.employmentType',
    dimensionCategory: EgaproDimensionCategory.EGAPRO_EMPLOYMENT_TYPE,
    measure: MEASURE_TITLES.EGAPRO_COUNT_WORKFORCE_IN_CALCULATION,
    associatedDimensions: [
      DIMENSION_TITLES.EGAPRO_DISPLAY_NAME,
      DIMENSION_TITLES.EGAPRO_EMPLOYMENT_TYPE
    ],
    orderDimension: DIMENSION_TITLES.EGAPRO_EMPLOYMENT_TYPE
  },
  {
    titleTranslationKey: 'legalReports.egaproIndex.setupTable.categories.fte',
    dimensionCategory: EgaproDimensionCategory.EGAPRO_FTE_FINANCE,
    measure: MEASURE_TITLES.EGAPRO_COUNT_WORKFORCE_IN_CALCULATION,
    associatedDimensions: [
      DIMENSION_TITLES.EGAPRO_DISPLAY_NAME,
      DIMENSION_TITLES.EGAPRO_FTE_FINANCE
    ],
    orderDimension: DIMENSION_TITLES.EGAPRO_FTE_FINANCE
  },
  {
    titleTranslationKey:
      'legalReports.egaproIndex.setupTable.categories.gender',
    dimensionCategory: EgaproDimensionCategory.EGAPRO_GENDER,
    measure: MEASURE_TITLES.EGAPRO_COUNT_WORKFORCE_IN_CALCULATION,
    associatedDimensions: [
      DIMENSION_TITLES.EGAPRO_DISPLAY_NAME,
      DIMENSION_TITLES.EGAPRO_GENDER
    ],
    orderDimension: DIMENSION_TITLES.EGAPRO_GENDER
  },
  {
    titleTranslationKey:
      'legalReports.egaproIndex.setupTable.categories.customCategorization',
    dimensionCategory: EgaproDimensionCategory.EGAPRO_CUSTOM_CATEGORY,
    measure: MEASURE_TITLES.EGAPRO_COUNT_WORKFORCE_IN_CALCULATION,
    associatedDimensions: [
      DIMENSION_TITLES.EGAPRO_DISPLAY_NAME,
      DIMENSION_TITLES.EGAPRO_CUSTOM_CATEGORY
    ],
    orderDimension: DIMENSION_TITLES.EGAPRO_CUSTOM_CATEGORY
  },
  {
    titleTranslationKey:
      'legalReports.egaproIndex.setupTable.categories.professionalCategory',
    dimensionCategory:
      EgaproDimensionCategory.EGAPRO_PROFESSIONAL_CATEGORY_TYPE,
    measure: MEASURE_TITLES.EGAPRO_COUNT_WORKFORCE_IN_CALCULATION,
    associatedDimensions: [
      DIMENSION_TITLES.EGAPRO_DISPLAY_NAME,
      DIMENSION_TITLES.EGAPRO_PROFESSIONAL_CATEGORY_TYPE
    ],
    orderDimension: DIMENSION_TITLES.EGAPRO_PROFESSIONAL_CATEGORY_TYPE
  },
  {
    titleTranslationKey:
      'legalReports.egaproIndex.setupTable.categories.ageRange',
    dimensionCategory: EgaproDimensionCategory.EGAPRO_AGE_RANGE,
    measure: MEASURE_TITLES.EGAPRO_COUNT_WORKFORCE_IN_CALCULATION,
    associatedDimensions: [
      DIMENSION_TITLES.EGAPRO_DISPLAY_NAME,
      DIMENSION_TITLES.EGAPRO_AGE_RANGE
    ],
    orderDimension: DIMENSION_TITLES.EGAPRO_AGE_RANGE
  },
  {
    titleTranslationKey:
      'legalReports.egaproIndex.setupTable.categories.salary',
    dimensionCategory: EgaproDimensionCategory.EGAPRO_REMUNERATION,
    measure: MEASURE_TITLES.EGAPRO_COUNT_WORKFORCE_IN_CALCULATION,
    associatedDimensions: [
      DIMENSION_TITLES.EGAPRO_DISPLAY_NAME,
      DIMENSION_TITLES.EGAPRO_ANNUAL_PAYROLL_TOTAL_EUR,
      DIMENSION_TITLES.EGAPRO_IS_IN_TOP_10_ANNUAL_PAYROLL
    ],
    orderDimension: DIMENSION_TITLES.EGAPRO_ANNUAL_PAYROLL_TOTAL_EUR
  },
  {
    titleTranslationKey:
      'legalReports.egaproIndex.setupTable.categories.salaryRaise',
    dimensionCategory: EgaproDimensionCategory.EGAPRO_SALARY_RAISE,
    measure: MEASURE_TITLES.EGAPRO_COUNT_SALARY_RAISE,
    associatedDimensions: [
      DIMENSION_TITLES.EGAPRO_LAST_SALARY_RAISE_DATE,
      DIMENSION_TITLES.EGAPRO_DISPLAY_NAME,
      DIMENSION_TITLES.EGAPRO_SALARY_BASE_RAISE
    ],
    orderDimension: DIMENSION_TITLES.EGAPRO_LAST_SALARY_RAISE_DATE
  },
  {
    titleTranslationKey:
      'legalReports.egaproIndex.setupTable.categories.promotion',
    dimensionCategory: EgaproDimensionCategory.EGAPRO_PROMOTION,
    measure: MEASURE_TITLES.EGAPRO_COUNT_PROMOTION,
    associatedDimensions: [
      DIMENSION_TITLES.EGAPRO_LAST_PROMOTION_DATE,
      DIMENSION_TITLES.EGAPRO_DISPLAY_NAME,
      DIMENSION_TITLES.EGAPRO_CAREER_LEVEL,
      DIMENSION_TITLES.EGAPRO_PREVIOUS_CAREER_LEVEL
    ],
    orderDimension: DIMENSION_TITLES.EGAPRO_LAST_PROMOTION_DATE
  },
  {
    titleTranslationKey:
      'legalReports.egaproIndex.setupTable.categories.maternityLeave',
    dimensionCategory: EgaproDimensionCategory.EGAPRO_MATERNITY_LEAVE,
    measure: MEASURE_TITLES.EGAPRO_COUNT_HAS_RETURNED_FROM_MATERNITY_LEAVE,
    associatedDimensions: [
      DIMENSION_TITLES.EGAPRO_DISPLAY_NAME,
      DIMENSION_TITLES.EGAPRO_MATERNITY_TYPE,
      DIMENSION_TITLES.EGAPRO_IS_RAISED_ON_RETURN_OR_DURING_MATERNITY_LEAVE,
      DIMENSION_TITLES.EGAPRO_MATERNITY_LEAVE_START_DATE,
      DIMENSION_TITLES.EGAPRO_MATERNITY_LEAVE_END_DATE
    ],
    orderDimension:
      DIMENSION_TITLES.EGAPRO_IS_RAISED_ON_RETURN_OR_DURING_MATERNITY_LEAVE
  }
]

export const SOURCE_VALUE_DIMENSION_PER_EGAPRO_DIMENSION_CATEGORY: Partial<{
  [key in EgaproDimensionCategory]: SourceValueDimension
}> = {
  [EgaproDimensionCategory.EGAPRO_EMPLOYMENT_TYPE]:
    SourceValueDimension.EMPLOYMENT_CATEGORY,
  [EgaproDimensionCategory.EGAPRO_PROFESSIONAL_CATEGORY_TYPE]:
    SourceValueDimension.PROFESSIONAL_CATEGORY,
  [EgaproDimensionCategory.EGAPRO_REMUNERATION]:
    SourceValueDimension.PAYROLL_CATEGORY,
  [EgaproDimensionCategory.EGAPRO_MATERNITY_LEAVE]:
    SourceValueDimension.ABSENTEEISM_CATEGORY
}

export const EGAPRO_INDEX_SCORE_BASE = {
  TOTAL: '100',
  GENDER_PAY_GAP: '40',
  SMALL_COMPANY_GENDER_PAY_RAISE_GAP: '35',
  LARGE_COMPANY_GENDER_PAY_RAISE_GAP: '20',
  GENDER_PROMOTION_GAP: '15',
  PAY_RAISE_AFTER_MATERNITY_LEAVE: '15',
  TOP_HIGHEST_SALARIES: '10'
}
